<template>
  <div>
    <b-container fluid>
      <div class="pagdiv">
        <span class="pageTitle">使用者管理</span>
      </div>
      <b-row class="borderColor">
        <div style="width: 100%">
          <div
            style="
              margin: 10px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;
            "
          >
            <div class="inputDiv">
              <i class="fas fa-search mr-2"></i
              ><input
                type="text"
                name="filter"
                id=""
                v-model="searchInput"
                class="filterInput"
                :placeholder="'依' + selectValue.name + '搜尋'"
              />
              <span class="focus-border"></span>
            </div>
            <select style="margin-right: 10px" v-model="selectValue">
              <option
                v-for="(i, index) in filterSelection"
                :key="index"
                :value="i"
              >
                {{ i.name }}
              </option>
            </select>
            <p
              style="
                margin: 0px;
                padding: 0px;
                font-weight: 700;
                font-size: 18px;
              "
            >
              資料筆數: {{ allUserDataLength }}
            </p>
            <div style="margin-left: auto">
              <button
                class="confirmButton"
                style="margin: 5px; width: 120px; padding: 5px"
                @click="openCreateUserModal"
              >
                新增帳戶
              </button>
            </div>
          </div>
          <b-overlay :show="loadingData" :style="minHeight">
            <div class="othertableStyle tableWrap">
              <table>
                <tr class="sticky-top">
                  <th v-for="(i, index) in thTitle" :key="index" class="sort">
                    {{ i.name }}
                  </th>
                </tr>
                <tr v-for="(i, idx) in allUserSearch" :key="idx">
                  <td>{{ i.account }}</td>

                  <td>{{ i.displayName }}</td>

                  <!-- factory -->
                  <td>
                    <div>
                      <span v-for="(x, index) in i.factoriesList" :key="index"
                        >{{ x
                        }}<span v-if="index !== i.factoriesList.length - 1"
                          >，</span
                        >
                      </span>
                    </div>
                  </td>

                  <!-- demo -->
                  <td>
                    <div>
                      <span v-for="(x, index) in i.demoList" :key="index"
                        >{{ x
                        }}<span v-if="index !== i.demoList.length - 1">，</span>
                      </span>
                    </div>
                  </td>

                  <td>{{ i.role }}</td>

                  <td
                    @click="
                      openEditUserModal(
                        i.account,
                        i.displayName,
                        i.role,
                        i.factoriesList,
                        i.demoList,
                        i.id
                      )
                    "
                    style="cursor: pointer"
                  >
                    <i class="fas fa-user-cog"></i>
                  </td>
                </tr>
              </table>
            </div>
          </b-overlay>
        </div>
      </b-row>
    </b-container>

    <b-modal id="userModal" size="lg" :title="modalTitle" hide-footer>
      <div class="othertableStyle">
        <table class="newUserStyle">
          <tr>
            <th>標題</th>
            <th>內容</th>
          </tr>

          <tr>
            <td>帳號</td>
            <td>
              <input
                style="width: 100%"
                v-model="account"
                :disabled="singleModal"
                placeholder="請輸入帳號"
                onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'')"
              />
              <!-- 只能輸入英文 -->
            </td>
          </tr>

          <tr>
            <td>密碼</td>
            <td>
              <input
                style="width: 100%"
                v-model="password"
                :disabled="!isEditable"
                :placeholder="passwordPlaceholder"
                autocomplete="off"
                onkeyup="value=value.replace(/[\W]/g,'')"
              />
              <!-- 只能輸入英文及數字 -->
            </td>
          </tr>

          <tr>
            <td>稱呼</td>
            <td>
              <input
                style="width: 100%"
                v-model="displayName"
                :disabled="!isEditable"
                placeholder="中英文皆可"
                onkeyup="this.value=this.value.replace(/[, ]/g,'')"
              />
              <!-- 不能有空格或英文逗號 -->
            </td>
          </tr>

          <tr>
            <td>可觀看案場</td>
            <td>
              <div class="checkFather">
                <div
                  @click.stop="openFactoryCheckBox"
                  style="min-height: 20px; text-align: left; cursor: pointer"
                >
                  <span v-if="factorySelect.length">
                    <span v-for="(i, idx) in factorySelect" :key="idx">
                      {{ i }}
                      <span v-if="idx !== factorySelect.length - 1">，</span>
                    </span>
                  </span>
                  <span v-else> 尚未選擇 </span>
                </div>
                <div class="checkBoxStyle" v-if="isOpenFactoryCheckBox">
                  <div class="d-flex align-items-center justify-content-end">
                    篩選:
                    <input
                      type="text"
                      v-model="factoryCheckBoxFilterInput"
                      placeholder="案場名稱搜尋"
                      style="margin-right: auto; margin-left: 4px"
                    />
                    <button
                      class="btn downlaodbuttonstyle btn-secondary"
                      style="width: 80px; padding: 2px; background: red"
                      @click.stop="openFactoryCheckBox"
                    >
                      關閉
                    </button>
                  </div>
                  <b-form-checkbox-group
                    v-model="factorySelect"
                    :options="factoryCheckBoxFilter"
                    plain
                    stacked
                    @change="handleChangeFactorySelect"
                  />
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>可觀看展示案場</td>
            <td>
              <div class="checkFather">
                <div
                  @click.stop="openDemoCheckBox"
                  style="min-height: 20px; text-align: left; cursor: pointer"
                >
                  <span v-if="demoSelect?.length">
                    <span v-for="(i, idx) in demoSelect" :key="idx">
                      {{ i }}
                      <span v-if="idx !== demoSelect.length - 1">，</span>
                    </span>
                  </span>
                  <span v-else> 尚未選擇 </span>
                </div>
                <div class="checkBoxStyle" v-if="isOpenDemoCheckBox">
                  <div class="d-flex align-items-center justify-content-end">
                    篩選:
                    <input
                      type="text"
                      v-model="demoCheckBoxFilterInput"
                      placeholder="案場名稱搜尋"
                      style="margin-right: auto; margin-left: 4px"
                    />
                    <button
                      class="btn downlaodbuttonstyle btn-secondary"
                      style="width: 80px; padding: 2px; background: red"
                      @click.stop="openDemoCheckBox"
                    >
                      關閉
                    </button>
                  </div>
                  <div class="my-1">(最多選擇 5 個)</div>
                  <b-form-checkbox-group
                    v-model="demoSelect"
                    :options="demoCheckBoxFilter"
                    plain
                    stacked
                    @change="handleChangeDemoSelect"
                  />
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>權限</td>
            <td>
              <select style="width: 100%" v-model="registerRole">
                <option v-for="(i, idx) in roleOption" :key="idx" :value="i">
                  {{ i }}
                </option>
              </select>
            </td>
          </tr>
        </table>
      </div>
      <div style="display: flex; justify-content: flex-end" v-if="!singleModal">
        <button
          class="confirmButton"
          style="width: 80px; padding: 2px"
          @click="createUser()"
        >
          創建
        </button>
      </div>
      <div style="display: flex; justify-content: flex-end" v-if="singleModal">
        <b-overlay :show="updateData">
          <button
            class="btn downlaodbuttonstyle btn-secondary"
            style="
              width: 100px;
              padding: 2px;
              margin-right: 20px;
              background: red;
            "
            @click="checkDeleteUser()"
          >
            刪除使用者
          </button>
          <button
            class="confirmButton"
            style="width: 80px; padding: 2px; margin-top: 8px"
            @click="updateUserInfo()"
          >
            更新
          </button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { getLoginRequest } from '../http/msalMethods'
import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import {
  getAllUsersApi,
  createUserApi,
  updateAccountApi,
  deleteUserApi
} from '../api'
export default {
  components: {
    BOverlay
  },
  data () {
    return {
      selectValue: {
        name: '帳號',
        filter: 'account'
      },
      id: '',
      operator: '',
      managerRole: '',
      updateData: false,
      modalTitle: '',
      loadingData: false,
      isOpenFactoryCheckBox: false,
      factoryCheckBoxFilterInput: '',
      isOpenDemoCheckBox: false,
      demoCheckBoxFilterInput: '',
      searchInput: '',
      factorySelect: [],
      factoryArray: [],
      demoSelect: [],
      demoArray: [],
      allUserData: [],
      registerRole: 'Admin',
      role: [
        'Whole_Admin',
        'Admin',
        'OM_Head',
        'Advance_User',
        'Mid_User',
        'Basic_User'
      ],
      thTitle: [
        {
          name: '帳號',
          filter: 'account'
        },
        {
          name: '稱呼',
          filter: 'displayName'
        },
        {
          name: '可觀看案場',
          filter: 'factoriesList'
        },
        {
          name: '可觀看展示案場',
          filter: 'demoList'
        },
        {
          name: '權限',
          filter: 'role'
        },
        {
          name: '設定',
          filter: ''
        }
      ],
      account: '',
      password: '',
      passwordPlaceholder: '請輸入密碼',
      displayName: '',
      singleModal: false,
      isEditable: true
    }
  },
  computed: {
    ...mapState(['factoryInfo', 'roleArray', 'userDetail']),
    minHeight () {
      const height = this.loadingData ? 'min-height: 75vh' : 'min-height: auto'
      return height
    },
    factoryCheckBoxFilter () {
      return this.factoryArray.filter((x) =>
        x.match(this.factoryCheckBoxFilterInput)
      )
    },
    demoCheckBoxFilter () {
      return this.demoArray.filter((x) =>
        x.match(this.demoCheckBoxFilterInput)
      )
    },
    allUserDataLength () {
      return this.allUserRoleFilter.length
    },
    allUserRoleFilter () {
      if (this.managerRole === 'Whole_Admin') {
        return this.allUserData
      }
      return this.allUserData.filter((x) => x.role !== 'Whole_Admin')
    },
    filterSelection () {
      return this.thTitle.filter((x) => x.name !== '設定')
    },
    allUserSearch () {
      if (this.selectValue.filter === 'factoriesList') {
        return this.allUserRoleFilter.filter((x) =>
          x.factoriesList.toString().match(this.searchInput)
        )
      }
      if (this.selectValue.filter === 'demoList') {
        return this.allUserRoleFilter.filter((x) =>
          x.demoList.toString().match(this.searchInput)
        )
      }
      return this.allUserRoleFilter.filter((x) =>
        x[`${this.selectValue.filter}`].match(this.searchInput)
      )
    },
    roleOption () {
      const roleOption = []
      this.roleArray.forEach((x) => roleOption.push(x.role))
      if (this.managerRole === 'Whole_Admin') {
        return roleOption
      } else if (this.managerRole === 'Admin') {
        return roleOption.filter((x) => x !== 'Whole_Admin')
      }
      return roleOption.filter((x) => x !== 'Whole_Admin' && x !== 'Admin')
    }
  },
  methods: {
    ...mapActions(['asyncInfo']),
    ...mapMutations(['UPDATE_ROLE_ARRAY', 'ADD_USER_DETAIL']),
    openCreateUserModal () {
      this.account = ''
      this.password = ''
      this.displayName = ''
      this.registerRole = 'Admin'
      this.factorySelect = []
      this.demoSelect = []
      this.passwordPlaceholder = '請輸入密碼'
      this.singleModal = false
      this.modalTitle = '新增帳號'
      this.updateData = false
      this.$bvModal.show('userModal')
    },
    openEditUserModal (account, displayName, role, factoryArr, demoArr, id) {
      this.account = account
      this.displayName = displayName
      this.factorySelect = factoryArr
      this.demoSelect = demoArr
      this.registerRole = role
      this.id = id
      this.singleModal = true
      this.modalTitle = '編輯帳號'
      this.passwordPlaceholder = '若要修改，請輸入新密碼'
      this.updateData = false
      this.$bvModal.show('userModal')
    },
    openFactoryCheckBox () {
      this.isOpenFactoryCheckBox = !this.isOpenFactoryCheckBox
      this.isOpenDemoCheckBox = false
    },
    openDemoCheckBox () {
      this.isOpenDemoCheckBox = !this.isOpenDemoCheckBox
      this.isOpenFactoryCheckBox = false
    },
    roleLevel (str) {
      if (str === 'Whole_Admin') return 6
      if (str === 'Admin') return 5
      if (str === 'OM_Head') return 4
      if (str === 'Advance_User') return 3
      if (str === 'Mid_User') return 2
      if (str === 'Basic_User') return 1
      if (str === 'unset') return 0
      return null
    },
    handleChangeFactorySelect () {
      const lastSelectedItem = [...this.factorySelect].pop()

      if (lastSelectedItem === '無') return (this.factorySelect = ['無'])
      if (lastSelectedItem === 'ALL') return (this.factorySelect = ['ALL'])

      this.factorySelect = this.factorySelect.filter(
        (item) => item !== '無' && item !== 'ALL'
      )
    },
    handleChangeDemoSelect () {
      const lastSelectedItem = [...this.demoSelect].pop()

      if (lastSelectedItem === '無') return (this.demoSelect = ['無'])

      if (this.demoSelect.length > 5) {
        this.$swal('展示案場最多選擇 5 個')
        this.demoSelect = this.demoSelect.slice(0, 5)
      }

      this.demoSelect = this.demoSelect.filter((item) => item !== '無')
    },
    async createUser () {
      if (this.account === '') return this.$swal('請輸入帳號')
      if (this.password === '') return this.$swal('請輸入密碼')
      if (this.displayName === '') return this.$swal('請輸入稱呼')
      if (this.factorySelect.length === 0) {
        return this.$swal('請選擇 可觀看案場')
      }
      if (this.demoSelect.length === 0) {
        return this.$swal('請選擇 可觀看展示案場')
      }
      if (this.factorySelect[0] === '無' && this.demoSelect[0] === '無') {
        return this.$swal('至少選擇 1 個 觀看案場 或 展示案場')
      }

      if (this.allUserData.map((obj) => obj.account).includes(this.account)) {
        return this.$swal('帳號已被使用')
      }

      this.updateData = true
      const createUser = await createUserApi(
        this.operator,
        this.account,
        this.password,
        this.displayName,
        this.registerRole,
        this.factorySelect,
        this.demoSelect,
        this.operator
      )
      if (createUser?.data?.success) {
        this.$swal('創建成功')
        this.account = ''
        this.password = ''
        this.displayName = ''
        this.getAllusers()
        this.updateData = false
        this.$bvModal.hide('userModal')
      } else {
        this.$swal(createUser.data.msg)
      }
    },
    async getAllusers () {
      this.loadingData = true
      const getAllusers = await getAllUsersApi()
      if (getAllusers?.data?.success) {
        this.allUserData = getAllusers.data.data
          .sort((x, y) => this.roleLevel(y.role) - this.roleLevel(x.role))
          .map((item) => {
            // factoriesList 或 demoList 至少會有 1 個
            if (!item.factoriesList || item.factoriesList?.length === 0) {
              return { ...item, factoriesList: ['無'] }
            }
            if (!item.demoList || item.demoList?.length === 0) {
              return { ...item, demoList: ['無'] }
            }
            return item
          })

        this.loadingData = false
      }
    },
    async updateUserInfo () {
      if (this.displayName === '') return this.$swal('請輸入稱呼')
      if (this.factorySelect.length === 0) {
        return this.$swal('請選擇 可觀看案場')
      }
      if (this.demoSelect.length === 0) {
        return this.$swal('請選擇 可觀看展示案場')
      }
      if (this.factorySelect[0] === '無' && this.demoSelect[0] === '無') {
        return this.$swal('至少選擇 1 個 觀看案場 或 展示案場')
      }

      this.updateData = true

      const updateAccount = await this.updateaccount()

      if (this.operator === this.account) {
        this.managerRole = this.registerRole
        this.$store.state.realTimeDataReload = true
        const userDetail = JSON.parse(localStorage.getItem('userDetail'))
        userDetail.account = this.account
        userDetail.displayName = this.displayName
        userDetail.factoriesList = this.factorySelect
        userDetail.demoList = this.demoSelect
        userDetail.role = this.registerRole
        userDetail.id = this.id
        localStorage.setItem('userDetail', JSON.stringify(userDetail))
        this.UPDATE_ROLE_ARRAY()
      }

      if (updateAccount) {
        this.$swal('更新成功')
        this.account = ''
        this.password = ''
        this.displayName = ''
        this.getAllusers()

        // store 更新使用者資訊
        const userDetail = JSON.parse(localStorage.getItem('userDetail'))
        this.ADD_USER_DETAIL(userDetail)

        // 更新選擇選單
        this.factoryArray = []
        this.demoArray = []
        await this.asyncInfo()

        this.factoryArray.push('無', 'ALL')
        this.demoArray.push('無')

        this.factoryInfo.forEach((x) => {
          if (!x.displaySiteName.includes('展示案場')) {
            this.factoryArray.push(x.displaySiteName)
            this.demoArray.push(x.displaySiteName)
          }
        })
      }

      this.updateData = false
      this.$bvModal.hide('userModal')
    },
    async updateaccount () {
      this.factorySelect = this.factorySelect.filter((x) => x !== '')
      const updateaccount = await updateAccountApi(
        this.operator,
        this.id,
        this.account,
        this.password,
        this.displayName,
        this.registerRole,
        this.factorySelect,
        this.demoSelect
      )
      if (updateaccount?.data?.success) {
        this.allUserData.forEach((x) => {
          if (x.account === this.account) {
            x.factoriesList = this.factorySelect
            x.demoList = this.demoSelect
            x.role = this.registerRole
          }
        })
        return true
      } else {
        this.$swal(updateaccount.data.msg)
        return false
      }
    },
    async deleteUser () {
      const deleteUser = await deleteUserApi(
        this.operator,
        this.id,
        this.account
      )
      if (deleteUser?.data?.success) {
        this.$swal('刪除成功')
        this.getAllusers()
        this.$bvModal.hide('userModal')
      }
    },
    async checkDeleteUser () {
      this.$swal
        .fire({
          title: `確定要刪除此使用者?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `確定`,
          denyButtonText: `否`
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteUser()
          }
        })
    }
  },
  async mounted () {
    this.operator = this.userDetail.account
    this.managerRole = this.userDetail.role
    this.getAllusers()
    await this.asyncInfo()

    this.factoryArray.push('無', 'ALL')
    this.demoArray.push('無')

    this.factoryInfo.forEach((x) => {
      if (!x.displaySiteName.includes('展示案場')) {
        this.factoryArray.push(x.displaySiteName)
        this.demoArray.push(x.displaySiteName)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/global.scss";
@import "../assets/scss/customVariables.scss";

.fontStyle {
  font-size: 16px;
  font-weight: 700;
  p {
    padding: 5px 0px;
    margin: 0;
  }
}
.popoverStyle {
  max-height: 300px;
  overflow: auto;
}
.sort {
  position: relative;
  cursor: pointer;
}
.sort::after {
  content: "";
  display: block;
  position: absolute;
  right: 2px;
  top: calc(50% - 9px);
  width: 20px;
  height: 20px;
}
.down::after {
  background: #7dc6cd url(../image/sortBtn02.png);
  background-size: 100%;
}
.up::after {
  background: #7dc6cd url(../image/sortBtn03.png);
  background-size: 100%;
}
.inputwidth {
  width: 100px;
}
.normal {
  color: rgb(109, 139, 109);
}
table {
  width: 100% !important;
}

table th {
  text-align: center;
}

table td {
  font-size: 17px;
  font-weight: 700;
  padding: 7px;
  text-align: center;
}

table td:nth-child(3) {
  text-align: left;
  max-width: 500px;
  div {
    max-height: 70px;
    overflow: auto;
  }
}

table td:nth-child(4) {
  text-align: left;
  max-width: 200px;
  div {
    max-height: 70px;
    overflow: auto;
  }
}

.newUserStyle {
  td {
    max-width: 200px;
  }
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
.iconstyle {
  font-size: 24px;
  margin: 7px;
  color: gray;
}
.checkFather {
  position: relative;
}
.checkBoxStyle {
  position: absolute;
  width: 100%;
  background: aliceblue;
  text-align: start;
  overflow: auto;
  max-height: 250px;
  padding: 5px;
  z-index: 99;
}
.tableWrap {
  height: calc(100vh - 200px);
  overflow-x: auto;

  table {
    th {
      background-color: rgb(245, 245, 245);
    }
  }
  @media screen and (max-width: 552px) {
    height: calc(100vh - 295px);
  }
}
</style>
